import React from 'react';
import Link from '../atoms/link';
import lock from '../../images/lock.svg';
import call from '../../images/phone.png';
import logo from '../../images/yes-logo.png';


// const logo2 = 'https://place-puppy.com/825x297';
// const logo1 = 'https://place-puppy.com/888x199';

const Header = ({
	type='main',
	content,
	hideSecureForm = false,
	showFormSecureBanner = false
}) => {
	const createClassList = () => {
		let classList = 'co-header';
		if (type) classList = classList + ' co-header--' + type;
		return classList;
	};

	const secureLock = (hideSecureForm) ? null : (
		<>
			<img src={lock} alt={content.rightColumnText} />
			<span>{content.rightColumnText}</span>
		</>
	);

	const primaryLogo = (
		<div className='co-header__logo-primary'>
			{/* <Link url='/' image> */}
				<img src={logo} alt='Logo' />
			{/* </Link> */}
		</div>
	);

	const secureBanner = (showFormSecureBanner) ? (
		<div className='co-header__banner'>
			{secureLock}
		</div>
	) : null;

	return (
		<div className={createClassList()}>
			<div className='co-header__wrapper'>
				<div className='co-header__main-menu'>
					<div className='l-container'>
						<div className='l-grid__row'>
							<div className='l-grid__col-xs-7 l-grid__col-md-8 co-header__logo'>
								{primaryLogo}
								{/* <div className="co-header__logo-secondary">
									<img src={logo2} alt="Logo" />
								</div> */}
							</div>

							<div className='l-grid__col-xs-5 l-grid__col-md-4 co-header__secure'>
								{secureLock}
							</div>
							{/* <div className='l-grid__col-xs-5 l-grid__col-md-4 co-header__contact'>
								<a href="tel:8552313339">
									<span className="u-desktop__show">(855) 231-3339</span>
									<span className="u-desktop__hide"><img src={call} alt="Contact us icon" /></span>
								</a>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{/* {secureBanner} */}
		</div>
	);
};

export default Header;
