import React from 'react';
import Link from '../atoms/link';

const Footer = ({
	pageDisclaimer = false
}) => {
	const pageDisclaimerContent = (pageDisclaimer) ? (
		<p>{pageDisclaimer}</p>
	) : null;

	return (
		<div className='co-footer'>
			<div className='co-footer__main'>
				<div className='l-container'>
					<div className='l-grid__row l-grid__middle-xs co-footer__logo-links'>
						<div className='l-grid__col-md co-footer__links--secondary'>
							<Link url='Privacy' target="_blank" classNames='co-footer__link'>
								Privacy Policy
							</Link>
							<Link url='https://yeshearing.com/terms-conditions/' target="_blank" classNames='co-footer__link'>
								Terms &amp; Services
							</Link>
							{/* <Link url='#' target="_blank" classNames='co-footer__link'>
								Contact Us
							</Link> */}
							{/* <Link url='/marketing-partners/' classNames='co-footer__link'>
								Marketing Partners
							</Link> */}
						</div>
					</div>
					<div className='l-grid__row'>
						<div className='l-grid__col-xs-12 l-spacing__m-t--24 co-footer__disclaimer'>
							{pageDisclaimerContent}
							{/* <p>[--Placeholder-Disclaimer-1--]</p>
							<br />
							<p>[--Placeholder-Disclaimer-2--]</p> */}
						</div>
					</div>
				</div>
			</div>
			<div className='co-footer__copyright'>
				<div className='l-container'>
					<div className='l-grid__row'>
						<div className='l-grid__col-xs'>
							<p>&copy; Copyright {new Date().getFullYear()} Yes Hearing. All rights reserved.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
